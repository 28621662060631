<template>
    <div class="news-detail">
        <head-img desc="新闻中心" :imgUrl="newsBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList"  :menuList="menuList"></my-menu>
        <div class="detail-content">
            <div class="content">
                <div class="head">
                    <div class="title">
                        {{information.matchName}}
                    </div>
                    <div class="date-address">
                        <div class="data">
                            <i class="el-icon-time"></i>
                            <span>
                                {{information.begDate}} ~ {{information.endDate}}
                            </span>
                        </div>
                        <div class="address">
                            <i class="el-icon-location"></i>
                            <span>
                                {{information.address}}
                            </span>
                        </div>
                    </div>
                    
                </div>
                <div class="pic-list">
                    <el-image class="news-img" v-for="item in picList" :key="item.url" :src="getImg(item.url)" fit="cover"></el-image>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newsBgUrl: require('@/assets/img/home/lunbo6.jpg'),
            contentUrl: require('@/assets/img/news/course1.jpg'),
            titleList: [
                //{name: '骑马必须要注意的几个细节'},
            ],
            menuList: [
                {name: '首页', path: '/'},
                {name: '新闻中心', path: '/news'},
                {name: ''},
            ],
            information: {},
            picList: []
        }
    },
    methods:{
        getDetail() {
            this.$store.commit('loading', '.news-detail .detail-content')
            this.$api.post('base/match/match-showForHomePage', this.$route.query).then(res=>{
                //console.log(res)
                this.information = res.data
                this.menuList[2].name = res.data.matchName
                if(res.data.picUrl){
                    res.data.picUrl.split(',').forEach(ele=>{
                        this.picList.push({url: ele})
                    })
                }
               //console.log(this.picList)
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }
    },
    mounted() {
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .news-detail{
        .detail-content{
            display: flex;
            justify-content: center;
            .content{
                margin: 50px 0;
                width: 1200px;
                .head{
                    margin-bottom: 30px;
                    .title{
                        font-size: 20px;
                        color: #262626;
                    
                    }
                    .date-address{
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .pic-list{
                    display: flex;
                    flex-wrap: wrap;
                    .news-img{
                        margin: 0 20px 20px 0;
                    }
                }
            
                
            }
        }
    }

</style>